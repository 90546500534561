<section class="mb-12">
  <div class="head flex items-end	mb-4">
    <h3 class="text-xl">Ma nouvelle adresse</h3>
    <span
      (click)="edit()"
      class="text-primary font-bold cursor-pointer ml-auto text-sm"
    >
      Modifier
    </span>
  </div>
  <div class="sm:flex bg-grey/10 rounded-4xl px-8 py-8 sm:px-12">
    <div class="flex-1 mb-8 sm:mb-0">
      <div>
        <h4 class="text-sm font-medium">Nouvelle adresse de fourniture</h4>
        <span class="text-sm">
          {{ registration?.sites?.[0]?.address?.street }},
          {{ registration?.sites?.[0]?.address?.streetNumber }}
          {{ registration?.sites?.[0]?.address?.box }}
          <br>
          {{ registration?.sites?.[0]?.address?.zip }}
          {{ registration?.sites?.[0]?.address?.city }}
          <br>
          {{ registration?.sites?.[0]?.address?.country }}
        </span>
      </div>

      <div>
        <h4 class="mt-6 text-sm font-medium">Adresse de facturation</h4>
        <span class="text-sm">
          <ng-container *ngIf="registration?.billingInfo?.useDeliveryAddress; else otherAddress">
            Identique à la nouvelle adresse de fourniture
          </ng-container>
        </span>
        <ng-template #otherAddress>
          {{ registration?.billingInfo?.address?.street }},
          {{ registration?.billingInfo?.address?.streetNumber }}
          {{ registration?.billingInfo?.address?.box }}
          <br>
          {{ registration?.billingInfo?.address?.zip }}
          {{ registration?.billingInfo?.address?.city }}
          <br>
          {{ registration?.billingInfo?.address?.country }}
        </ng-template>
      </div>
    </div>
    <div class="flex-1">
      <div>
        <h4 class="text-sm font-medium">Recevoir mes factures par e-mail</h4>
        <span class="text-sm">
          <ng-container *ngIf="registration?.billingInfo?.invoiceSendingType === InvoicingMethod.EMAIL; else post">
            Oui (EMAIL)
          </ng-container>
          <ng-template #post>
            Non
          </ng-template>
        </span>
      </div>

      <div>
        <h4 class="mt-6 text-sm font-medium">Document de reprise d'énergie</h4>
        <span class="text-sm">
        <ng-container *ngIf="registration?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE; else hasHandover">
          Non
        </ng-container>
        <ng-template #hasHandover>
          Oui
          <span *ngIf="registration?.documents?.length > 0"> (document chargé) </span>
        </ng-template>
        </span>
      </div>
    </div>
  </div>
</section>

<div [formGroup]="metersFormGroup">
  <div formGroupName="electricity">
    <div class="flex mt-8 items-center mb-8">
      <img ngSrc="./assets/img/icons/standalone/default/electricity.svg" height="30" width="24">
      <h3 class="text-lg ml-4">Électricité</h3>
    </div>
    <p>
      Renseignez toutes les informations relatives au compteur électrique présent à votre nouvelle adresse. Il vous est possible d’en ajouter plusieurs si nécessaire.
    </p>
    <section class="border-b border-grey/35 pb-12 mb-12">
      <div class="my-8">
        <p class="form-element input-checkbox errors mt-4">
          <input type="checkbox" formControlName="hasMeter" id="hasMeter" />
          <label for="hasMeter">
            Il n’y a pas encore de <strong>compteur électrique</strong> installé à ma nouvelle adresse
          </label>
        </p>
      </div>

      <div class="sm:flex gap-8 mb-8">
        <p class="w-full sm:w-1/2 form-element input-text mt-4">
          <label>EAN</label>
          <input onlyNumber type="text" formControlName="ean" placeholder="5414XXXXXXXXXXXXXX" />
        </p>
        <p class="w-full sm:w-1/2 form-element input-text mt-4">
          <label>Numéro de compteur</label>
          <input type="text" formControlName="meterNumber" placeholder="XXXXXXXX" />
        </p>
      </div>

      <app-custom-select
        [field]="{
          id: 'meterType',
          label: 'Type de compteur',
          formControl: metersFormGroup.get('electricity.meterType'),
          options: meterTypeOptions
        }"
      >
      </app-custom-select>


      <div class="sm:flex gap-8 mb-8">
        <p class="w-full sm:w-1/2 form-element input-checkbox mt-4">
          <input type="checkbox" formControlName="exclusiveNight" id="exclusiveNight" />
          <label for="exclusiveNight">
            Je dispose d'un compteur <strong>exclusif nuit</strong>
          </label>
        </p>
        <p class="w-full sm:w-1/2 form-element input-checkbox mt-4">
          <input type="checkbox" formControlName="smartMeter" id="smartMeter" />
          <label for="smartMeter">
            Je dispose d'un compteur <strong>intelligent</strong>
          </label>
        </p>
      </div>

      <div
        *ngIf="region === Region.flanders && metersFormGroup.get('electricity.smartMeter').value"
        class="form-element input-text has-suffix max-w-96"
      >
        <label for="peakPower"> Puissance de pointe </label>
        <div class="flex">
          <input id="peakPower" type="text" placeholder="0" formControlName="peakPower" />
          <div class="suffix"> KVA </div>
        </div>
      </div>

    </section>

    <section class="">

      <p class="form-element input-checkbox-big errors mt-8">
        <input type="checkbox" id="hasSolarPanels" formControlName="hasSolarPanels">
        <label for="hasSolarPanels">
          <div class="inline-block w-full">
            <div class="max-w-full parent">
              Je dispose de panneaux solaires
              <div class="content">
                <div class="sm:flex gap-8 mb-8">
                  <div class="w-full form-element input-text has-suffix">
                    <label for="solarPanelsKva"> Solar Panels KVA </label>
                    <div class="flex">
                      <input id="solarPanelsKva" type="text" formControlName="solarPanelsKva" placeholder="2.5" style="padding-top: 14px; padding-bottom: 14px;"/>
                      <div class="suffix"> KVA </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="region === Region.wallonia && metersFormGroup.get('electricity.smartMeter').value" class="sm:flex gap-8 mb-8">
                  <div class="w-full sm:w-1/2 form-element input-checkbox mt-4">
                    <input id="injectionTariff" type="checkbox" formControlName="injectionTariff" />
                    <label for="injectionTariff">
                      Je dispose du tarif d'injection
                    </label>
                  </div>
                  <div class="w-full sm:w-1/2">
                    <p class="form-element input-radio mt-4">
                      <input id="before2024" type="radio" formControlName="solarPanelsInstallationDate" value="BEFORE_2024" />
                      <label for="before2024"> PV installé avant 2024 </label>
                    </p>
                    <p class="form-element input-radio mt-4">
                      <input id="after2024" type="radio" formControlName="solarPanelsInstallationDate" value="AFTER_2024" />
                      <label for="after2024"> PV installé après 2024 </label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </label>
      </p>

    </section>

    <section *ngIf="metersFormGroup.get('electricity.indexes')?.['length']" class="border-t border-grey/35 pt-12 mt-12">
      <div class="md:flex flex-wrap indexes">
        <div class="w-full md:w-1/2 overflow-hidden" *ngFor="let indexControl of metersFormGroup.get('electricity.indexes')?.['controls']">
          <app-input-meter-index
            [style]="InputMeterStyle.INLINE"
            [type]="indexControl?.get('type')?.value"
            [value]="indexControl?.get('value')?.value"
            [control]="indexControl"
            [newType]="indexControl.get('timeFrame').value"
            [unit]="'general.units.kwh' | translate"
            [label]="'components.estimatedIndexDetail.form.meterInput.'+ indexControl.get('timeFrame').value + '.' + indexControl.get('type').value +'.label' | translate"
          >
          </app-input-meter-index>
        </div>
      </div>
    </section>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MainFacade } from '@app/core/facade/main.facade';
import { MoveDataCuzoApi, MoveDataStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { take } from 'rxjs';

@Component({
  selector: 'app-move-cta',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './move-cta.component.html',
  styleUrls: ['./move-cta.component.scss'],
})
export class MoveCtaComponent implements OnInit {
  @Input() moveData: MoveDataCuzoApi;
  @Input() siteId: string;
  readonly: boolean = false;

  constructor(private readonly facade: MainFacade, private readonly moveFormFacade: MoveFormFacade) {}

  ngOnInit(): void {
    this.readonly = this.moveData?.status !== MoveDataStatusEnumCuzoApi.INITIATED;
  }

  resumeMove() {
    const reference: string = this.facade.state$.value.reference;
    this.moveFormFacade.resumeMove(reference, this.siteId, this.moveData.moveId);
  }

  archive() {
    const reference: string = this.facade.state$.value.reference;
    this.moveFormFacade
      .archive(reference, this.siteId, this.moveData?.moveId)
      .pipe(take(1))
      .subscribe(() =>
        this.facade.proxyRouter.navigateByUrl('/foo', { skipLocationChange: true }).then(
          () => this.moveFormFacade.redirectToMoveDashboard() // Refresh status to get latest view
        )
      );
  }
}

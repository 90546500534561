<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      Résumé
    </h2>
    <p class="mb-8">
      Vérifiez bien les détails fournis avant de finaliser la procédure.
    </p>

    <app-summary-moving-info
      [moveDTO]="moveDTO"
      (editEmitter)="edit($event)"
    >
    </app-summary-moving-info>

    <app-summary-my-meters
      [moveDTO]="moveDTO"
      (editEmitter)="edit($event)"
    >
    </app-summary-my-meters>

    <app-summary-new-address
      [registration]="registration"
      (editEmitter)="edit($event)"
    >
    </app-summary-new-address>

    <app-summary-new-meters
      [registration]="registration"
      (editEmitter)="edit($event)"
    >
    </app-summary-new-meters>

  </div>
</div>

<app-navigation
  [stepValidity]="true"
  (nextClick)="onNextClicked()"
  (previousClick)="onPreviousClicked()"
>
</app-navigation>

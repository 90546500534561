<div [formGroup]="dreFormGroup">
  <h3 class="text-2xl font-medium text-grey-dark mt-12">Document de reprise d'énergie</h3>
  <div class="grid gap-8 mt-8">
    <app-expandable-radio-btn
      name="dreStatus"
      [label]="'pages.move.dreDocument.full.title' | translate"
      [subtitle]="'pages.move.dreDocument.full.subtitle' | translate"
      [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL"
      [selectedValue]="dreStatusControl.value"
      (selectedValueChange)="onDreStatusChange($event)"
    >
      <div class="p-10 pt-0">
        <te-input-file
          id="file_full"
          fileWrapperClass="md:!w-4/5"
          fileType="application/pdf, .jpg, .jpeg, .png"
          [buttonTitle]="'general.uploadFile' | translate"
          [loading]="uploadInProgress"
          [label]="'general.uploadFile' | translate"
          maxFileSize="12"
          (fileChanged)="onFileSelection($event)"
        >
        </te-input-file>
      </div>
    </app-expandable-radio-btn>

    <app-expandable-radio-btn
      name="dreStatus"
      [label]="'pages.move.dreDocument.partial.title' | translate"
      [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL"
      [selectedValue]="dreStatusControl.value"
      (selectedValueChange)="onDreStatusChange($event)"
    >
      <div class="p-10 pt-2">
        <te-input-file
          id="file_partial"
          fileWrapperClass="md:!w-4/5"
          fileType="application/pdf, .jpg, .jpeg, .png"
          [buttonTitle]="'general.uploadFile' | translate"
          [loading]="uploadInProgress"
          [label]="'general.uploadFile' | translate"
          maxFileSize="12"
          (fileChanged)="onFileSelection($event)"
        >
        </te-input-file>
      </div>
    </app-expandable-radio-btn>

    <app-expandable-radio-btn
      name="dreStatus"
      [label]="'pages.move.dreDocument.noDocument.title' | translate"
      [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE"
      [selectedValue]="dreStatusControl.value"
      (selectedValueChange)="onDreStatusChange($event)"
    >
    </app-expandable-radio-btn>
  </div>
  <app-alert
    class="inline-block mt-16 w-full"
    [flatDesign]="true"
    [alert]="{
          type: AlertType.info,
          content: {
            message: 'Le document de reprise des énergies est un formulaire à compléter lorsqu\'il y a un changement de locataire au sein d\'un logement. Vous pouvez télécharger un document de reprise d\’énergies vierge pour le compléter',
          },
        }"
  ></app-alert>
</div>

<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      Nouveaux compteurs
    </h2>
    <div [formGroup]="form">
      <div [ngSwitch]="step">
        <section *ngSwitchCase="1">
          <app-energy-type
            formGroupName="energyType"
            [deliveryPoints]="moveOutDeliveryPoints"
            (formValidityEmitter)="setFormValidity($event)"
          >
          </app-energy-type>
        </section>

        <section *ngSwitchCase="2">
          <div *ngIf="meters?.hasElectricity;else gas">
            <app-electricity-meter
              formGroupName="meters"
              [registration]="registration"
              [moveInDate]="form.get('energyType.date').value"
              (formValidityEmitter)="setFormValidity($event)"
            >
            </app-electricity-meter>
          </div>
        </section>

        <section *ngSwitchCase="3">
          <ng-template [ngTemplateOutlet]="gas"></ng-template>
        </section>
      </div>

      <ng-template #gas>
        <app-gas-meter
          formGroupName="meters"
          (formValidityEmitter)="setFormValidity($event)"
        >
        </app-gas-meter>
      </ng-template>
    </div>
  </div>
</div>

<app-navigation
  [stepValidity]="isCurrentStepValid"
  (nextClick)="onNextClicked()"
  (previousClick)="onPreviousClicked()"
>
</app-navigation>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { InputFileUploadComponent } from '@app/shared/components/input-file-upload/input-file-upload.component';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import {
  catchError,
  delay,
  filter,
  iif,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { EnergyType, RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import {
  DeliveryPoint,
  INITIAL_MOVE_STATE,
  Meter,
  MetersByEnergy,
  MoveDTO,
  MoveMeter,
  MoveRegister,
  MoveSite,
  MoveState,
} from '@app/core/state/move.state';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { SharedModule } from '@app/shared/shared.module';
import { PreSwitchLight } from '@app/modules/customer-zone/move/models/api.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MetersControlComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/meters-control/meters-control.component';
import { EanControlComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/ean-control/ean-control.component';
import { DeliveryPointService } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/delivery-point.service';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { GhostTableComponent } from '@app/shared/components/ghost-table/ghost-table.component';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';

@Component({
  selector: 'app-my-meters',
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    DatePickerFieldModule,
    InputFileUploadComponent,
    NavigationComponent,
    ReactiveFormsModule,
    NgOptimizedImage,
    SharedModule,
    TranslateModule,
    MetersControlComponent,
    EanControlComponent,
    GhostTableComponent,
  ],
  templateUrl: './my-meters.component.html',
  styleUrls: ['./my-meters.component.scss'],
})
export class MyMetersComponent extends MoveFormStep<MoveDTO> implements OnInit, OnDestroy {
  readonly AlertType = AlertType;
  readonly EnergyType = EnergyType;
  form: FormGroup;
  meters$: Observable<ApiResponse<MetersByEnergy>>;
  notifier: Subject<void> = new Subject<void>();
  loadingState: Map<string, boolean> = new Map<string, boolean>();
  isAnyEnergySelected: boolean = false;

  constructor(
    protected readonly moveFormFacade: MoveFormFacade,
    private readonly formBuilder: FormBuilder,
    private readonly deliveryPointService: DeliveryPointService
  ) {
    super(moveFormFacade);
  }

  getMetersFormValue(ean: string): FormArray {
    return this.form.get(`${ean}.meters`) as FormArray;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
    this.monitorEnergySelection();

    this.meters$ = this.moveFormFacade.moveDTO$.pipe(
      filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
      take(1),
      switchMap((moveDTO: MoveDTO) =>
        this.getMetersByEnergy().pipe(
          take(1),
          catchError(() => {
            return of({ error: true });
          }),
          tap((response: ApiResponse<MetersByEnergy>): void => {
            if (response?.data) {
              const metersByEnergy: MetersByEnergy = response?.data;
              this.initialiseFormWithMeters(metersByEnergy);
              const deliveryPoints: DeliveryPoint[] = moveDTO?.sites?.[0]?.deliveryPoints;
              if (deliveryPoints?.length) {
                deliveryPoints.forEach((dp: DeliveryPoint): void => {
                  this.addDeliveryPointAndCreateMeterIndexes(dp, dp?.meters);
                });
              }
              this.moveFormFacade.updateData({ moveDTO });
            }
          }),
          map(
            (response: ApiResponse<MetersByEnergy>): ApiResponse<MetersByEnergy> => ({
              loading: false,
              data: response?.data,
            })
          ),
          startWith({ loading: true })
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  onNextClickedDefault(): void {
    if (this.form.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.saveFormData().subscribe(() => {
        this.moveFormFacade.next();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
    }
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.previous();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
  }

  onCheckboxChange(event: Event, meter: Meter): void {
    const { checked }: { checked: boolean } = <HTMLInputElement>event.target;
    if (checked) {
      this.loadingState.set(meter?.ean, true);
      const deliveryPoint: DeliveryPoint = this.deliveryPointService.createDeliveryPoint(meter);
      this.deliveryPointService.addDeliveryPoint(meter?.ean, deliveryPoint);
    } else {
      this.form.get(meter?.ean).get('selected').setValue(false);
      this.deliveryPointService.removeDeliveryPoint(meter?.ean);
    }

    // Update moveDTO value state
    const deliveryPoints: DeliveryPoint[] = this.deliveryPointService.getListOfDeliveryPoints();
    const moveDTO: MoveDTO = this.updatedMoveDeliveryPoint(deliveryPoints);
    this.moveFormFacade.updateData({ moveDTO });

    // PUT new moveDTO & call preswitch (add if this.deliveryPointMap.size > 0)
    const hasMissingMetersFields: boolean = deliveryPoints.some((dp: DeliveryPoint) => this.hasNoMeterFields(dp.code));
    this.moveFormFacade
      .update(moveDTO)
      .pipe(
        take(1),
        delay(1000),
        switchMap(
          (): Observable<PreSwitchLight[]> => iif(() => checked, this.moveFormFacade.getPreSwitchLight(), of(null))
        )
      )
      .subscribe((response: PreSwitchLight[]): void => {
        this.loadingState.set(meter?.ean, false);
        if (response) {
          const data: PreSwitchLight = response.find((entry: PreSwitchLight): boolean => entry.ean === meter?.ean);
          const dp: DeliveryPoint = this.deliveryPointService.get(meter?.ean);
          this.addDeliveryPointAndCreateMeterIndexes(dp, data?.meters);
          this.moveFormFacade.updateData({ preSwitchLight: response });
        }
      });
  }

  isLoadingData(): boolean {
    return Array.from(this.loadingState.values()).some((value: boolean) => value);
  }

  saveFormData(): Observable<MoveDTO> {
    const state: MoveState = this.moveFormFacade.state$.value;
    const deliveryPoints: DeliveryPoint[] = state?.moveDTO?.sites?.[0]?.deliveryPoints;
    const allMetersHaveType: boolean = this.checkAllMetersHaveType(deliveryPoints);
    const allDpHaveId: boolean = this.checkAllDeliveryPointsHasId(deliveryPoints);
    let observable$: Observable<MoveDTO>;

    if (allMetersHaveType && allDpHaveId) {
      const deliveryPointsWithTypes: DeliveryPoint[] = this.buildDeliveryPoints();
      observable$ = this.updateMoveData(deliveryPointsWithTypes);
    } else {
      observable$ = this.moveFormFacade.get().pipe(
        switchMap((move: MoveDTO) => {
          const fetchedDeliveryPoints: DeliveryPoint[] = move?.sites?.[0]?.deliveryPoints;
          const deliveryPointsWithFetchedTypes: DeliveryPoint[] = this.buildDeliveryPoints(fetchedDeliveryPoints);
          return this.updateMoveData(deliveryPointsWithFetchedTypes);
        })
      );
    }

    return observable$;
  }

  private buildDeliveryPoints(fetchedDeliveryPoints: DeliveryPoint[] = null): DeliveryPoint[] {
    const deliveryPoints: DeliveryPoint[] = Array.from(this.deliveryPointService.deliveryPointMap.values());
    return deliveryPoints.map(
      (dp: DeliveryPoint): DeliveryPoint => ({
        ...dp,
        id: dp?.id || this.getIdFromResponse(dp?.code, fetchedDeliveryPoints),
        meters: dp?.meters?.map(
          (meter: MoveMeter, meterIndex: number): MoveMeter => ({
            ...meter,
            type: meter?.type || this.getMeterTypeFromResponse(dp?.code, meter, fetchedDeliveryPoints),
            number: meter?.number || meter?.meterNumber,
            registers: meter?.registers?.map(
              (register: MoveRegister, index: number): MoveRegister => ({
                timeFrame: this.timeFrameMapper(register?.timeFrame),
                type: this.directionMapper(register?.type || register?.direction),
                value: this.getIndexFormValue(dp?.code, meterIndex, index),
              })
            ),
          })
        ),
      })
    );
  }

  private timeFrameMapper(timeFrame: RegisterType): RegisterType {
    if (timeFrame === RegisterType.TOTAL_HOUR) {
      return RegisterType.MONO;
    }
    return timeFrame;
  }

  private directionMapper(direction: Direction) {
    if (direction === Direction.consumption) {
      return Direction.reading;
    }
    if (direction === Direction.production) {
      return Direction.injectionReading;
    }
    return direction;
  }

  private getIdFromResponse(dpCode: string, fetchedDeliveryPoints: DeliveryPoint[]): string {
    return fetchedDeliveryPoints?.find(({ code }): boolean => dpCode === code).id;
  }

  private getMeterTypeFromResponse(dpCode: string, meter: MoveMeter, fetchedDeliveryPoints: DeliveryPoint[]): string {
    return fetchedDeliveryPoints
      ?.find(({ code }): boolean => dpCode === code)
      ?.meters?.find(({ number }) => [meter?.number, meter?.meterNumber].includes(number))?.type;
  }

  private checkAllDeliveryPointsHasId(deliveryPoints: DeliveryPoint[]): boolean {
    return deliveryPoints?.length > 0 && deliveryPoints.every((dp: DeliveryPoint) => dp.hasOwnProperty('id'));
  }

  private checkAllMetersHaveType(deliveryPoints: DeliveryPoint[]): boolean {
    return (
      deliveryPoints?.length > 0 &&
      deliveryPoints.every(
        (dp: DeliveryPoint) =>
          dp?.meters.length > 0 && dp?.meters?.every((meter: MoveMeter) => meter.hasOwnProperty('type'))
      )
    );
  }

  private updateMoveData(deliveryPoints: DeliveryPoint[]): Observable<MoveDTO> {
    const moveDTO: MoveDTO = this.updatedMoveDeliveryPoint(deliveryPoints);
    this.moveFormFacade.updateData({ moveDTO });
    return this.moveFormFacade.update(moveDTO).pipe(take(1));
  }

  private getIndexFormValue(ean: string, meterIndex: number, index: number): string {
    const metersFormArray: FormArray = <FormArray>this.form.get(`${ean}.meters`);
    const indexesFormArray: FormArray = <FormArray>metersFormArray?.at(meterIndex)?.get('indexes');
    const indexFormGroup: FormGroup = <FormGroup>indexesFormArray?.at(index);
    const unitValue: string = indexFormGroup?.get('unit').value;
    const decimalValue: string = indexFormGroup?.get('decimal').value;
    return !unitValue && !decimalValue ? null : `${unitValue || 0}.${decimalValue || 0}`;
  }

  private addDeliveryPointAndCreateMeterIndexes(dp: DeliveryPoint, meters: MoveMeter[]): void {
    if (meters?.length) {
      const deliveryPoint: DeliveryPoint = this.deliveryPointService.enrichDeliveryPointWithExtraInfo(dp, meters);
      this.deliveryPointService.addDeliveryPoint(dp?.code, deliveryPoint);
      this.createMeterIndexes(dp, meters);
      this.form.get(dp?.code)?.get('selected').setValue(true);
    }
  }

  private createMeterIndexes(dp: DeliveryPoint, meters: MoveMeter[]): void {
    if (this.hasNoMeterFields(dp?.code)) {
      const metersFields: FormGroup[] = meters.map((meter: MoveMeter): FormGroup => this.createMeterFormGroup(meter));
      metersFields.forEach((meter: FormGroup) => this.getMetersFormValue(dp?.code).push(meter));
    }
  }

  private hasNoMeterFields(ean: string): boolean {
    return this.getMetersFormValue(ean)?.length === 0;
  }

  private getMetersByEnergy(): Observable<ApiResponse<MetersByEnergy>> {
    return this.moveFormFacade.getAvailableMeters().pipe(
      catchError((error) => of({ error: true })),
      map((response: ApiResponse<Meter[]>): ApiResponse<MetersByEnergy> => {
        return {
          data: {
            electricity: response?.data
              .filter((meter: Meter): boolean => meter.energy === EnergyType.ELECTRICITY)
              .filter(
                (item: Meter, index: number, self: Meter[]): boolean =>
                  index === self.findIndex((m: Meter): boolean => m?.ean === item.ean)
              ),
            gas: response?.data
              .filter((meter: Meter): boolean => meter.energy === EnergyType.GAS)
              .filter(
                (item: Meter, index: number, self: Meter[]): boolean =>
                  index === self.findIndex((m: Meter): boolean => m?.ean === item.ean)
              ),
          },
          loading: false,
        };
      })
    );
  }

  private initialiseFormWithMeters(metersByEnergy: MetersByEnergy): void {
    const meters: Meter[] = metersByEnergy.electricity.concat(metersByEnergy.gas);
    meters.forEach((meter: Meter) =>
      this.form.addControl(
        meter.ean,
        this.formBuilder.group({
          selected: this.formBuilder.control(false),
          meters: this.formBuilder.array([]),
        })
      )
    );
  }

  private createMeterFormGroup(meter: MoveMeter): FormGroup {
    return this.formBuilder.group({
      meterNumber: meter.number,
      indexes: this.formBuilder.array(
        meter.registers.map((register: MoveRegister) =>
          this.formBuilder.group({
            timeFrame: [register?.timeFrame],
            type: [register?.direction],
            unit: [register?.value?.split('.')[0]],
            decimal: [register?.value?.split('.')[1]],
            value: [register?.value],
          })
        )
      ),
    });
  }

  private monitorEnergySelection(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.notifier),
        map((formValues) => Object.values(formValues).some((value) => value['selected']))
      )
      .subscribe((isAnyEnergySelected: boolean) => (this.isAnyEnergySelected = isAnyEnergySelected));
  }

  private updatedMoveDeliveryPoint(deliveryPoints: DeliveryPoint[]): MoveDTO {
    const moveDTO: MoveDTO = this.moveFormFacade.state$.value.moveDTO;
    const [site]: MoveSite[] = moveDTO.sites;
    site.deliveryPoints = site?.deliveryPoints?.length
      ? this.addIdGeneratedByBackend(site, deliveryPoints)
      : deliveryPoints;
    return moveDTO;
  }

  private addIdGeneratedByBackend(site: MoveSite, deliveryPoints: DeliveryPoint[]): DeliveryPoint[] {
    return deliveryPoints.map(
      (dp: DeliveryPoint): DeliveryPoint => ({
        id: site?.deliveryPoints?.find(({ code }): boolean => code === dp.code)?.id,
        ...dp,
      })
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlContainer, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { fileExtensionValidator, fileSizeValidator } from '@app/shared/utils/utils.validators';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { InputFileUploadComponent } from '@app/shared/components/input-file-upload/input-file-upload.component';
import { TranslateModule } from '@ngx-translate/core';
import { TotalenergiesUiLibraryModule } from '@totalenergiescode/springbox-totalenergies-ui-library';
import { ExpandableRadioBtnComponent } from '../expandable-radio-btn/expandable-radio-btn.component';

@Component({
  selector: 'app-dre-document',
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    InputFileUploadComponent,
    ReactiveFormsModule,
    TranslateModule,
    TotalenergiesUiLibraryModule,
    ExpandableRadioBtnComponent,
  ],
  templateUrl: './dre-document.component.html',
  styleUrls: ['./dre-document.component.scss'],
})
export class DreDocumentComponent implements OnInit {
  readonly UploadDocumentParamsDreCompletionStatusEnumCuzoApi = UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
  readonly AlertType = AlertType;
  @Output() fileEmitter: EventEmitter<File> = new EventEmitter<File>();
  @Input() fileName?: string = null;
  @Input() uploadInProgress: boolean = false;
  validExtensions: string[] = ['pdf', 'jpg', 'jpeg', 'png'];
  maxFileSizeMB: number = 15;

  constructor(private controlContainer: ControlContainer) {}

  static hasFileToUpload(dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi) {
    return [
      UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL,
      UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL,
    ].includes(dreCompletionStatus);
  }

  get dreFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get fileControl(): AbstractControl {
    return this.dreFormGroup.get('file');
  }

  get dreStatusControl(): AbstractControl {
    return this.dreFormGroup.get('dreStatus');
  }

  ngOnInit(): void {
    this.updateValidators(this.dreStatusControl.value);
    this.dreStatusControl.valueChanges.subscribe(
      (dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi): void => {
        this.updateValidators(dreStatus);
      }
    );
  }

  setFileValidity() {
    this.fileControl.addValidators([
      fileExtensionValidator(this.validExtensions),
      fileSizeValidator(this.maxFileSizeMB),
    ]);
    this.fileControl.updateValueAndValidity();
  }

  onFileSelection(event: Event) {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFile: File = input.files[0];
      this.fileName = selectedFile.name;
      this.fileEmitter.emit(selectedFile);
      this.setFileValidity();
    }
  }

  onDreStatusChange(dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi) {
    this.dreStatusControl.setValue(dreStatus);
  }

  private updateValidators(dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi) {
    if (DreDocumentComponent.hasFileToUpload(dreStatus)) {
      this.fileControl.addValidators([Validators.required]);
    } else {
      this.fileControl.removeValidators([Validators.required]);
    }
    this.fileControl.updateValueAndValidity();
  }
}

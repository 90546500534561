<div class="step-wrapper">
  <div
    class="step mb-16"
    [class.pointer-events-none]="isLoadingData()"
  >
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      Mes compteurs actuels
    </h2>
    <span>Sélectionnez le (les) compteur(s) que vous souhaitez déménager et renseignez leur index</span>
    <div [formGroup]="form">
      <div *ngIf="(meters$ | async) as meters">
        <div *ngIf="meters?.loading || meters?.data; else metersError">
          <div *ngIf="meters?.loading; else showMeters">
            <div>
              <div class="animate-pulse py-8">
                <div class="mb-4">
                  <div class="inline-block w-20 mr-6 bg-slate-100 h-16 rounded-xl"></div>
                  <div class="inline-block w-72 bg-slate-100 h-16 rounded-xl"></div>
                </div>
                <div class="w-full h-32 bg-slate-100 rounded-4xl"></div>
              </div>
            </div>
          </div>
          <ng-template #showMeters>
            <div *ngIf=" meters?.data?.electricity?.length">
              <div class="flex mt-12 items-center">
                <img ngSrc="./assets/img/icons/standalone/default/electricity.svg" height="30" width="24">
                <h3 class="text-lg ml-4">Électricité</h3>
              </div>
              <app-ean-control
                *ngFor="let meter of meters?.data?.electricity"
                [energyType]="EnergyType.ELECTRICITY"
                [meter]="meter"
                [formGroupName]="meter?.ean"
                [loadingState]="loadingState"
                (meterSelectionEmitter)="onCheckboxChange($event, meter)"
              >
              </app-ean-control>
            </div>

            <div *ngIf="meters?.data?.gas?.length">
              <div class="flex mt-12 items-center">
                <img ngSrc="./assets/img/icons/standalone/default/gas.svg" height="32" width="26">
                <h3 class="text-lg ml-4">Gaz</h3>
              </div>
              <app-ean-control
                *ngFor="let meter of meters?.data?.gas"
                [energyType]="EnergyType.GAS"
                [meter]="meter"
                [formGroupName]="meter?.ean"
                [loadingState]="loadingState"
                (meterSelectionEmitter)="onCheckboxChange($event, meter)"
              >
              </app-ean-control>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>

<app-navigation
  [stepValidity]="!isLoadingData() && isAnyEnergySelected && form.valid"
  (nextClick)="onNextClicked()"
  (previousClick)="onPreviousClicked()"
>
</app-navigation>

<ng-template #metersError>
  <app-alert
    class="inline-block mt-16 w-full"
    [flatDesign]="true"
    [alert]="{ type: AlertType.error, content: { message: 'Impossible de récupérer vos compteurs. Veuillez ré-essayer plus tard.' } }"
  ></app-alert>
</ng-template>

<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">Mon fournisseur</h2>
    <div>
      <p class="form-element input-radio-big errors mt-4">
        <input type="radio" id="stay" [value]="true" [formControl]="formControl" />
        <label for="stay">
          <div class="flex items-center">
            <div class="pr-6">
              Je déménage et <strong>je reste client TotalEnergies</strong>
              <span class="inline-block w-full text-sm text-grey-dark60/60"
                >Je continue à bénéficier du meilleur tarif et du meilleur service sur le marcher.</span
              >
            </div>
            <img ngSrc="./assets/img/macaron-te.svg" width="80" height="80" alt="TotalEnergies logo" />
          </div>
        </label>
      </p>
      <p class="form-element input-radio-big errors mt-8">
        <input type="radio" id="leave" [value]="false" [formControl]="formControl" />
        <label for="leave">Je déménage et je ne souscris plus de contrat à mon nom</label>
      </p>
    </div>
  </div>
</div>
<app-navigation [stepValidity]="formIsValid" (nextClick)="onNextClicked()" (previousClick)="onPreviousClicked()">
</app-navigation>

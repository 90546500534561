<div class="action sm:flex sm:mt-8 mt-12 text-sm text-primary font-bold">
  <div class="sm:mr-12 cursor-pointer" (click)="resumeMove()">
    <img ngSrc="assets/img/icons/standalone/red/eye.svg" width="16" height="12" class="inline-block mr-1 eye"/>
    Visualiser ma demande
  </div>
  <div *ngIf="!readonly" class="mt-8 sm:mt-0 sm:mr-8 cursor-pointer" (click)="archive()">
    <img ngSrc="assets/img/icons/standalone/red/trash.svg" width="16" height="17" class="inline-block mr-1"/>
    Annuler ma demande
  </div>
</div>

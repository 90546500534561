<section class="mb-12">
  <div class="head flex items-end	mb-4">
    <h3 class="text-xl">Mes compteurs</h3>
    <span
      class="text-primary font-bold cursor-pointer ml-auto text-sm"
      (click)="edit()"
    >Modifier</span>
  </div>
  <div class="sm:flex-row bg-grey/10 rounded-4xl px-8 py-8 sm:px-12">
    <div
      *ngIf="elecDeliveryPoint"
      class="electricity {{ gasDeliveryPoint ? 'border-b border-grey/35 pb-8 mb-8' : '' }}"
    >
      <h3 class="text-base font-medium mb-4">
        Compteur n°{{ elecMeter?.number }} (ELECTRICITY)
      </h3>
      <div class="flex">
        <div class="flex-1">
          <span class="inline-block w-full text-sm">
            EAN: {{ elecDeliveryPoint?.code }}
          </span>
          <span *ngIf="elecMeter?.type" class="inline-block w-full text-sm">
            MeterType: {{ elecMeter.type }}
          </span>
        </div>
        <div *ngIf="hasElecIndexes" class="flex-1">
          <h4 class="text-sm font-medium">Index</h4>
          <div class="mt-0">
            <ng-container *ngFor="let reading of elecMeter?.registers">
              <span *ngIf="reading?.value" class="inline-block w-full text-sm">
                {{ reading?.timeFrame }} {{ reading.type }}: {{ reading.value }} kWh
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="gasDeliveryPoint"
      class="gas"
    >
      <h3 class="text-base font-medium mb-4">
        Compteur n°{{ gasMeter?.number }} (GAS)
      </h3>
      <div class="flex">
        <div class="flex-1">
          <span class="inline-block w-full text-sm">
            EAN: {{ gasDeliveryPoint?.code }}
          </span>
          <span class="inline-block w-full text-sm">
            MeterType: {{ gasMeter.type }}
          </span>
        </div>
        <div *ngIf="hasGasIndexes" class="flex-1">
          <h4 class="text-sm font-medium">Index</h4>
          <div class="mt-0">
            <ng-container *ngFor="let reading of gasMeter?.registers">
              <span *ngIf="reading?.value" class="inline-block w-full text-sm">
                {{ reading?.timeFrame }} {{ reading.type }}: {{ reading.value }} kWh
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import { Injectable } from '@angular/core';
import { MoveDTO, MoveFormFrontend } from '@app/core/state/move.state';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { EnergyType, RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';

@Injectable({
  providedIn: 'root',
})
export class BackendToFrontendMapperService {
  static map(moveDTO: MoveDTO, registration: MoveInRegistration = null) {
    if (!moveDTO || !moveDTO?.id) {
      return {};
    }
    const localForm = JSON.parse(localStorage.getItem(moveDTO?.id))?.form;
    return {
      supplier: this.mapSupplierStep(moveDTO, localForm),
      movingInfo: this.mapMovingInfoStep(moveDTO, localForm),
      newAddress: this.mapNewAddressStep(registration, localForm),
      newMeters: this.mapNewMetersStep(registration, localForm),
      newOccupant: this.mapNewOccupantStep(registration, localForm),
    };
  }

  private static mapSupplierStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    return {
      amendmentField: moveDTO?.amendmentField ?? localForm?.supplier?.amendmentField,
    };
  }

  private static mapMovingInfoStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    const filename: string = moveDTO?.documents?.[moveDTO?.documents.length - 1]?.name || null;
    const dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi =
      moveDTO?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus;
    return {
      file: filename ?? localForm?.movingInfo?.file,
      dreStatus: dreStatus ?? localForm?.movingInfo?.dreStatus,
      movingDate: moveDTO?.ilcDate ?? localForm?.movingInfo?.movingDate,
    };
  }

  // TODO Fetch data from registration
  private static mapNewOccupantStep(registration: MoveInRegistration, localForm: MoveFormFrontend) {
    const newOccupantLocalStorage = localForm?.newOccupant;
    return {
      firstName: newOccupantLocalStorage?.firstName,
      lastName: newOccupantLocalStorage?.lastName,
      phone: newOccupantLocalStorage?.phone,
      contractType: newOccupantLocalStorage?.contractType,
      hasNewOccupant: newOccupantLocalStorage?.hasNewOccupant,
    };
  }

  private static mapNewAddressStep(registration: MoveInRegistration, localForm: MoveFormFrontend) {
    const filename: string = registration?.documents?.[registration?.documents.length - 1]?.name || null;
    return {
      newAddress: {
        address: registration?.sites?.[0]?.address?.street ?? localForm?.newAddress?.newAddress?.address,
        number: registration?.sites?.[0]?.address?.streetNumber ?? localForm?.newAddress?.newAddress?.number,
        box: registration?.sites?.[0]?.address?.box ?? localForm?.newAddress?.newAddress?.box,
        zipCode: registration?.sites?.[0]?.address?.zip ?? localForm?.newAddress?.newAddress?.zipCode,
        locality: registration?.sites?.[0]?.address?.city ?? localForm?.newAddress?.newAddress?.locality,
      },
      invoicingData: {
        invoicingMethod:
          registration?.billingInfo?.invoiceSendingType ?? localForm?.newAddress?.invoicingData?.invoicingMethod,
        hasDifferentInvoicingAddress:
          registration?.billingInfo?.useDeliveryAddress === false ??
          localForm?.newAddress?.invoicingData?.hasDifferentInvoicingAddress,
      },
      dre: {
        dreStatus:
          registration?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus ?? localForm?.newAddress?.dre?.dreStatus,
        file: filename ?? localForm?.newAddress?.dre?.file,
      },
    };
  }

  private static mapNewMetersStep(registration: MoveInRegistration, localForm: MoveFormFrontend) {
    const site = registration?.sites?.[0];

    const elecDeliveryPoint = site?.deliveryPoints?.find((dp) => dp.energyType === EnergyType.ELECTRICITY);
    const elecMeter = elecDeliveryPoint?.meters?.[0];
    const elecIndexes = localForm?.newMeters?.meters?.electricity?.indexes ?? elecMeter?.registers;

    const gasDeliveryPoint = site?.deliveryPoints?.find((dp) => dp.energyType === EnergyType.GAS);
    const gasMeter = gasDeliveryPoint?.meters?.[0];
    const gasIndexes = localForm?.newMeters?.meters?.gas?.indexes ?? gasMeter?.registers;

    return {
      energyType: {
        date: site?.deliveryPoints?.[0]?.requestedServiceDate ?? localForm?.newMeters?.energyType?.date,
      },
      meters: {
        electricity: {
          hasMeter: localForm?.newMeters?.meters?.electricity?.hasMeter,
          ean: elecDeliveryPoint?.code ?? localForm?.newMeters?.meters?.electricity?.ean,
          meterNumber: elecMeter?.meterNumber ?? localForm?.newMeters?.meters?.electricity?.meterNumber,
          meterType: elecMeter?.type ?? localForm?.newMeters?.meters?.electricity?.meterType,
          smartMeter: elecMeter?.smartMeter ?? localForm?.newMeters?.meters?.electricity?.smartMeter,
          exclusiveNight:
            elecIndexes?.some((index) => index.timeFrame === RegisterType.NIGHT_EXCLUSIVE) ??
            localForm?.newMeters?.meters?.electricity?.exclusiveNight,
          peakPower: site?.peakSolarPower ?? localForm?.newMeters?.meters?.electricity?.peakPower,
          hasSolarPanels: site?.hasSolarPanels ?? localForm?.newMeters?.meters?.electricity?.hasSolarPanels,
          solarPanelsKva: site?.solarPanelKVA ?? localForm?.newMeters?.meters?.electricity?.solarPanelsKva,
          solarPanelsInstallationDate:
            site?.solarPanelsInstalationDate ?? localForm?.newMeters?.meters?.electricity?.solarPanelsInstalationDate,
          injectionTariff: site?.hasInjectionTariff ?? localForm?.newMeters?.meters?.electricity?.injectionTariff,
          indexes: elecIndexes,
        },
        gas: {
          hasMeter: localForm?.newMeters?.meters?.gas?.hasMeter,
          ean: gasDeliveryPoint?.code ?? localForm?.newMeters?.meters?.gas?.ean,
          meterNumber: gasMeter?.number ?? localForm?.newMeters?.meters?.gas?.meterNumber,
          smartMeter: localForm?.newMeters?.meters?.gas?.smartMeter ?? gasMeter?.smartMeter,
          indexes: gasIndexes,
        },
      },
      step: localForm?.newMeters?.step,
    };
  }
}
